import React from "react";
import "./WorkProcess.css";
import WorkProcessStep from "../WorkProcessStep/WorkProcessStep";

import { ReactComponent as ShieldIcon } from "../../assets/svg/shield.svg";
import { ReactComponent as ChildIcon } from "../../assets/svg/child.svg";
import { ReactComponent as HandshakeIcon } from "../../assets/svg/handshake.svg";
import { ReactComponent as LunettesIcon } from "../../assets/svg/lunettes.svg";
import { ReactComponent as RunningIcon } from "../../assets/svg/running.svg";
import { ReactComponent as CardIcon } from "../../assets/svg/card.svg";


const WorkProcess: React.FC = () => {
  return (
    <div className="work-process">
      <h2 className="work-process-title">QUALITE EXCEPTIONNELLE</h2>
      <h1 className="work-process-subtitle">NOS ENGAGEMENTS</h1>
      <div className="steps-container">
        <WorkProcessStep
          Icon={ShieldIcon}
          label="01. GARANTIE CASSE"
          tooltipText="Excellence garantie"
        />
        <WorkProcessStep
          Icon={LunettesIcon}
          label="02. VERRES PROGRESSIFS"
          tooltipText="Logiciel de prise de mesure individualisé"
        />
        <WorkProcessStep
          Icon={RunningIcon}
          label="03. EQUIPEMENT SPORTIF"
          tooltipText="Confort tout type de sport"
        />
        <WorkProcessStep
          Icon={CardIcon}
          label="04. FACILITE DE PAIEMENT"
          tooltipText="Jusqu'à 3 fois sans frais"
        />
        <WorkProcessStep
          Icon={ChildIcon}
          label="05. ENFANTS ET MYOPIE"
          tooltipText="Tout défaut visuel dont la myopie évolutive"
        />
        <WorkProcessStep
          Icon={HandshakeIcon}
          label="06. PROFESSIONNELS AGEES MUTUELLES"
          tooltipText="Tiers payant "
        />
      </div>
    </div>
  );
};

export default WorkProcess;

import React from "react";
import "./image-text-block.css";

interface ImageTextBlockProps {
  imageSrc: string;
  text: string;
  title: string;
  altText?: string;
  reverse?: boolean;
}

const ImageTextBlock: React.FC<ImageTextBlockProps> = ({
  imageSrc,
  text,
  title,
  altText = "Image",
  reverse = false,
}) => {
  return (
    <div className={`image-text-block ${reverse ? "reverse" : ""}`}>
      <img src={imageSrc} alt={altText} className="image" />
      <div className="text-content">
        <span className="title">{title}</span>
        <span className="text">{text}</span>
      </div>
    </div>
  );
};

export default ImageTextBlock;

import React, { useState } from "react";
import "./WorkProcessStep.css";

interface WorkProcessStepProps {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  label: string;
  tooltipText: string;
}

const WorkProcessStep: React.FC<WorkProcessStepProps> = ({
  Icon,
  label,
  tooltipText,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="work-process-step"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered && <div className="tooltip">{tooltipText}</div>}
      <div className={`icon-container ${isHovered ? "hovered" : ""}`}>
        <Icon className="icon" />
      </div>
      <p className="label">{label}</p>
    </div>
  );
};

export default WorkProcessStep;

import React from "react";
import "./ContactForm.css";

const ContactForm: React.FC = () => {
  return (
    <div className="contact-form-container" id="contact">
      <div className="overlay-contact"></div>
      <div className="contact-form-content">
        <span className="contact-subtitle">VOUS AVEZ DES QUESTIONS ?</span>
        <span className="contact-title">CONTACTEZ-NOUS</span>
        <form>
          <div className="form-group">
            <input type="text" placeholder="Name *" />
            <input type="email" placeholder="Email *" />
          </div>
          <div className="form-group">
            <input type="text" placeholder="Subject" />
          </div>
          <div className="form-group">
            <textarea placeholder="Message *"></textarea>
          </div>
          <button type="submit">SEND EMAIL</button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;

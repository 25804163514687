import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import "./header.css";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  const toggleMenu = () => {
    if (window.innerWidth <= 768) {
      setIsMenuOpen(!isMenuOpen);
    }
  };
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMenuOpen]);

  return (
    <header className={`header ${isScrolled ? "scrolled" : ""}`}>
      <div className="logo-container-header">
        <span id="page-logo-header">
          <span className="red">O</span>PTIVISION
        </span>
      </div>
      <div className={`nav-container ${isMenuOpen ? "open" : ""}`}>
        <nav>
          <ul>
            <li>
              <Link
                to="home"
                smooth={true}
                duration={1000}
                offset={-70}
                onClick={toggleMenu}
              >
                ACCEUIL
              </Link>
            </li>
            <li>
              <Link
                to="about"
                smooth={true}
                duration={1000}
                offset={-70}
                onClick={toggleMenu}
              >
                A PROPOS DE NOUS
              </Link>
            </li>
            <li>
              <Link
                to="gallery"
                smooth={true}
                duration={1000}
                offset={-70}
                onClick={toggleMenu}
              >
                GALLERIE
              </Link>
            </li>
            <li>
              <Link
                to="contact"
                smooth={true}
                duration={1000}
                offset={-70}
                onClick={toggleMenu}
              >
                CONTACTEZ-NOUS
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="burger" onClick={toggleMenu}>
        <div className={`line1 ${isMenuOpen ? "open" : ""}`}></div>
        <div className={`line2 ${isMenuOpen ? "open" : ""}`}></div>
        <div className={`line3 ${isMenuOpen ? "open" : ""}`}></div>
      </div>
    </header>
  );
};

export default Header;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Pagination,
  Autoplay,
} from "swiper/modules";
import img1 from "../../assets/images/image1.avif";
import img2 from "../../assets/images/image2.avif";
import img3 from "../../assets/images/image3.avif";
import img4 from "../../assets/images/image4.webp";

import "./TestimonialSlider.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

interface Testimonial {
  image: string;
  name: string;
  title: string;
  quote: string;
}

const testimonials: Testimonial[] = [
  {
    image: img1,
    name: "MARIA LUCAS",
    title: "TREETHEMES",
    quote:
      " Tout était parfait! Excellent accueil et service, grande disponibilité pour nous aider à faire notre choix parmi leurs nombreux modèles. J'ai pu être très bien conseillée pour mes lunettes de vue et solaire. Je recommande sans hésitation !",
  },
  {
    image: img2,
    name: "JOHN DOE",
    title: "COMPANY INC.",
    quote: `Très professionnel et sympathique, j’ai été accueilli avec un café pour le moins virevoltant, rapide et efficace le vendeur m’a aidé à choisir une paire sans chercher pour autant à me vendre les plus cher, je recommande
Si je pouvais mettre une 6eme étoile je le ferais`,
  },
  {
    image: img3,
    name: "JOHN DOE",
    title: "COMPANY INC.",
    quote: `Rien à redire ! Un large choix nous est proposé, pour tous les goûts et tous les budgets. Nous sommes très bien accueillis et conseillés par Clément, qui fait son travail à la perfection. Ça fait plaisir de voir quelqu’un qui fait son métier par amour et non par contrainte.
Je recommande fortement !`,
  },
  {
    image: img4,
    name: "MARIA LUCAS",
    title: "TREETHEMES",
    quote:
      " Tout était parfait! Excellent accueil et service, grande disponibilité pour nous aider à faire notre choix parmi leurs nombreux modèles. J'ai pu être très bien conseillée pour mes lunettes de vue et solaire. Je recommande sans hésitation !",
  },
];

const TestimonialSlider: React.FC = () => {
  return (
    <div className="testimonial-slider">
      <h1 className="slider-subtitle">NOS CLIENTS</h1>

      <Swiper
        modules={[Pagination, Autoplay]}
        pagination={{ clickable: true }}
        autoplay= {{delay:5000}}
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className="testimonial-content">
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="testimonial-image"
              />
              <p className="testimonial-quote">{testimonial.quote}</p>
              <h3 className="testimonial-name">{testimonial.name}</h3>
              <p className="testimonial-title">{testimonial.title}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TestimonialSlider;

import React from "react";
import "./button.css";

interface ButtonProps {
  text: string;
  onClick: () => void;
  backgroundColor?: string;
  color?: string;
}

const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  backgroundColor = "blue",
  color = "white",
}) => {
  const buttonStyle = {
    backgroundColor: backgroundColor,
    color: color,
    borderColor: color,
  };

  return (
    <button className="button-style" style={buttonStyle} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;

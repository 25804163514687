import React from "react";
import "./ProductGallery.css";

import img1 from "../../assets/images/image1.avif";
import img11 from "../../assets/images/image11.avif";
import img2 from "../../assets/images/image2.avif";
import img22 from "../../assets/images/image22.avif";
import img3 from "../../assets/images/image3.avif";
import img33 from "../../assets/images/image33.avif";
import img4 from "../../assets/images/image4.webp";
import img44 from "../../assets/images/image44.webp";
import img5 from "../../assets/images/image5.webp";
import img55 from "../../assets/images/image55.webp";
import img6 from "../../assets/images/image6.webp";
import img66 from "../../assets/images/image66.webp";
import img7 from "../../assets/images/image7.webp";
import img77 from "../../assets/images/image77.webp";
import img8 from "../../assets/images/image8.webp";
import img88 from "../../assets/images/image88.webp";



import ProductGalleryItem from "../ProductGalleryItem/ProductGalleryItem";

const ProductGallery: React.FC = () => {
  return (
    <div className="product-gallery" id="gallery">
      <h2 className="gallery-title">GALLERIE</h2>
      <p className="gallery-subtitle">DECOUVREZ NOTRE COLLECTION</p>
      <div className="gallery-grid">
        <ProductGalleryItem
          mainImageSrc={img1}
          secondImageSrc={img11}
          title="THE MELI ICONS"
          subtitle="135 €"
        />
        <ProductGalleryItem
          mainImageSrc={img2}
          secondImageSrc={img22}
          title="THE ELVIE"
          subtitle="135 €"
        />
        <ProductGalleryItem
          mainImageSrc={img3}
          secondImageSrc={img33}
          title="THE ELVIE"
          subtitle="150 €"
        />
        <ProductGalleryItem
          mainImageSrc={img4}
          secondImageSrc={img44}
          title="THE COEUR"
          subtitle="135 €"
        />
        <ProductGalleryItem
          mainImageSrc={img5}
          secondImageSrc={img55}
          title="THE HOMETOWN"
          subtitle="135 €"
        />
        <ProductGalleryItem
          mainImageSrc={img6}
          secondImageSrc={img66}
          title="THE COEUR"
          subtitle="135 €"
        />
        <ProductGalleryItem
          mainImageSrc={img7}
          secondImageSrc={img77}
          title="THE DARREN"
          subtitle="135 €"
        />
        <ProductGalleryItem
          mainImageSrc={img8}
          secondImageSrc={img88}
          title="THE SWIRL 2"
          subtitle="135 €"
        />
      </div>
    </div>
  );
};

export default ProductGallery;

import React from "react";
import "./ProductGalleryItem.css";

interface ProductGalleryItemProps {
  mainImageSrc: string;
  secondImageSrc: string;
  title: string;
  subtitle: string;
}

const ProductGalleryItem: React.FC<ProductGalleryItemProps> = ({
  mainImageSrc,
  secondImageSrc,
  title,
  subtitle,
}) => {
  return (
    <div className="product-gallery-item-container">
      <div className="product-gallery-item">
        <img src={mainImageSrc} alt={title} className="product-image" />
        <div className="overlay">
          <div className="overlay-content">
            <img src={secondImageSrc} alt={title} className="second-image" />
          </div>
        </div>
      </div>
      <span className="gallery-item-title">{title}</span>
      <span className="gallery-item-subtitle">{subtitle}</span>
    </div>
  );
};

export default ProductGalleryItem;



import React, { useEffect, useRef, useState } from "react";
import "./MapComponent.css";

interface MapComponentProps {
  lat: number;
  lng: number;
  zoom?: number;
}

const MapComponent: React.FC<MapComponentProps> = ({ lat, lng, zoom = 14 }) => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

    useEffect(() => {
      const checkGoogleMaps = () => {
        if (typeof google !== "undefined" && google.maps) {
          setIsMapLoaded(true);
        } else {
          setTimeout(checkGoogleMaps, 100);
        }
      };

      checkGoogleMaps();
    }, []);

  useEffect(() => {
    if (isMapLoaded && mapRef.current) {
      const map = new google.maps.Map(mapRef.current, {
        center: { lat, lng },
        zoom,
      });

      new google.maps.Marker({
        position: { lat, lng },
        map,
        title: "Our Location",
      });
    }
  }, [lat, lng, zoom, isMapLoaded]);

  return (
    <div className="map-component">
      <div className="map-header">
        <span className="contact-title">NOTRE ADRESSE</span>
        <p className="contact-subtitle">
          Visitez-nous dans notre bureau situé en plein cœur de la ville. Nous
          sommes toujours ouverts pour vous accueillir.
        </p>
      </div>
      <div ref={mapRef} className="map-container"></div>
    </div>
  );
};

export default MapComponent;

import React from "react";
import "./homePage.css";
import { Flip } from "react-awesome-reveal";
import Button from "../components/button/Button";
import ImageTextBlock from "../components/image-text-block/ImageTextBlock";
import bannerImage from "../assets/images/tiers.webp";
import lunettes from "../assets/images/lunettes.webp";
import lentilles from "../assets/images/lentilles.webp";
import WorkProcess from "../components/WorkProcess/WorkProcess";
import ProductGallery from "../components/ProductGallery/ProductGallery";
import TestimonialSlider from "../components/TestimonialSlider/TestimonialSlider";
import ContactForm from "../components/ContactForm/ContactForm";
import MapComponent from "../components/MapComponent/MapComponent";
import Footer from "../components/Footer/Footer";
import { Link } from "react-scroll";

const HomePage = () => {
  return (
    <>
      <div className="home-page-banner" id="home">
        <div className="veil"></div>
        <div className="banner-content">
          <span className="banner-title">OPTIVISION</span>
          <div className="animated-text">
            <div className="flip-class-left">
              <Flip
                className="flip-class"
                direction="vertical"
                duration={250}
                cascade
                triggerOnce
              >
                <span className="letter red">V</span>
                <span className="letter">O</span>
                <span className="letter">T</span>
                <span className="letter">R</span>
                <span className="letter">E</span>
                <span className="letter-space"> </span>
                <span className="letter">V</span>
                <span className="letter">I</span>
                <span className="letter">S</span>
                <span className="letter">I</span>
                <span className="letter">O</span>
                <span className="letter">N</span>
                <span className="letter">.</span>
              </Flip>
            </div>

            <div className="flip-class">
              <Flip
                className="flip-class"
                direction="vertical"
                delay={2500}
                duration={250}
                cascade
                triggerOnce
              >
                <span className="letter red">N</span>
                <span className="letter">O</span>
                <span className="letter">T</span>
                <span className="letter">R</span>
                <span className="letter">E</span>
                <span className="letter-space"> </span>
                <span className="letter">M</span>
                <span className="letter">I</span>
                <span className="letter">S</span>
                <span className="letter">S</span>
                <span className="letter">I</span>
                <span className="letter">O</span>
                <span className="letter">N</span>
                <span className="letter">.</span>
              </Flip>
            </div>
          </div>
          <div className="logo-container">
            <span id="page-logo">
              <span className="red">O</span>PTIVISION
            </span>
          </div>
          <div className="banner-buttons">
            <Link to="about" smooth={true} duration={1000} offset={-70}>
              <Button
                text={"A PROPOS DE NOUS"}
                backgroundColor="white"
                color="black"
                onClick={function (): void {}}
              ></Button>
            </Link>
            <Link to="gallery" smooth={true} duration={1000} offset={-70}>
              <Button
                text={"NOTRE GALLERIE"}
                backgroundColor="rgb(255, 204, 2)"
                color="black"
                onClick={function (): void {}}
              ></Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="about-us-container" id="about">
        <div className="about-us">
          <span className="about-us-title">A PROPOS DE </span>
          <div className="logo-container-about-us">
            <span id="page-logo-about-us">
              <span className="red">O</span>PTIVISION
            </span>
          </div>
        </div>
        <span className="about-us-content">
          <span className="red black">O</span>PTIVISION EST VOTRE OPTICIEN À
          MAISON-ALFORT SITUÉ DANS LE CENTRE COMMERCIAL DU SUPERMARCHÉ CASINO À
          PROXIMITÉ DU MÉTRO ECOLE VÉTÉRINAIRE ET À MOINS DE 10 MINUTES DU
          CENTRE-VILLE. NOUS SOMMES TROIS COLLABORATEURS AYANT CHACUN UNE
          EXPÉRIENCE DE PLUS DE 10 ANS EN OPTIQUE LUNETTERIE. COMPLÉMENTAIRE ET
          PROFESSIONNELLE, NOTRE ÉQUIPE SAURA RÉPONDRE À VOS ATTENTES ET PRENDRE
          SOIN DE VOUS. VENEZ NOUS RENCONTRER!
        </span>
      </div>
      <ImageTextBlock
        imageSrc={bannerImage}
        reverse
        text={
          "Nous vous offrons la possibilité de pratiquer le tiers payant avec le plus grand nombre de mutuelles. Vous n'avez aucune démarche à faire, nous nous occupons de la part sécurité sociale et mutuelle."
        }
        title={"Tiers payant"}
      />
      <ImageTextBlock
        imageSrc={lunettes}
        text={
          "ZEISS Vision France fabrique des verres de marque ZEISS destinés au marché français (hors gammes spéciales) à Fougères, en Bretagne. Cela permet de satisfaire au mieux les opticiens en termes de délais, de service et de qualité, mais aussi les consommateurs qui souhaitent privilégier le « Made in France ». Bénéficiant du label « Origine France Garantie », ces verres sont faits sur mesure pour s’adapter parfaitement aux caractéristiques propres des porteurs, et à leurs besoins de tous les jours."
        }
        title={"Verres Carl Zeiss Vision France"}
      />
      <ImageTextBlock
        imageSrc={lentilles}
        reverse
        text={
          "Vous trouverez tous types et toutes marques de lentille de contact et de produits d'entretien dans notre magasin. Nous saurons vous conseiller et répondre à toutes les questions que vous vous posez concernant vos lentilles de contact et leur entretien."
        }
        title={"Lentilles de contact"}
      />
      <WorkProcess />

      <ProductGallery />

      <ContactForm />
      <TestimonialSlider />
      <MapComponent lat={-37.816279} lng={144.964873} zoom={15} />
      <Footer />
    </>
  );
};

export default HomePage;
